.selectCompany {
  width: 240px;
}
.dropdown {
  width: 420px;
}

@media screen and (max-width: 768px) {
  .selectCompany {
    max-width: 280px;
  }
}
