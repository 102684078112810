.modal :global(.ant-modal-content),
.modal :global(.ant-modal-header) {
  border-radius: var(--border-radius-3);
  background: #fff;
}

.modal :global(.ant-modal-close) {
  color: var(--primary-blue);
}

.modal :global(.ant-modal-header) {
  border: none;
}

.modal :global(.ant-modal-header) span,
.title {
  color: var(--dark-blue);
  font-weight: 700;
  line-height: 1.2;
}

.title {
  letter-spacing: -2px;
  font-size: var(--heading-size-3);
}

@media screen and (min-width: 922px) {
  .title {
    font-size: var(--heading-size-2);
  }
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: var(--heading-size-1);
  }
}

.modal :global(.ant-modal-title) {
  margin-top: 2rem;
}

.modal :global(.ant-modal-footer) {
  display: none;
  border-top: none;
}

.imgContainer {
  display: none;
  flex: 0 0 50%;
  margin: -24px 0 -24px -24px;
  padding-right: 6px;
  padding-left: 6px;
  max-width: 50%;
}

@media screen and (min-width: 768px) {
  .imgContainer {
    display: block;
  }
}

.imgContainer img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .row {
    padding-left: 24px;
  }
}
