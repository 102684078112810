.overlay :global(.ant-popover-inner) {
  position: relative;
  padding: 0.625rem;
  border-radius: var(--border-radius-3);
  max-width: 12.5rem;
}

.overlay :global(.ant-popover-arrow) {
  display: none;
}

.overlay :global(.ant-popover-inner):before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border-radius: var(--border-radius-3);
  background: linear-gradient(
    120.04deg,
    var(--primary-blue) 0%,
    #ff6047 87.93%,
    #f98d51 103.19%
  );
}

.overlay :global(.ant-popover-title) {
  border: none;
  padding: 0 0 0.5rem;
  font-size: var(--p-size-3);
  font-weight: 600;
  color: var(--dark-blue);
}

.overlay :global(.ant-popover-inner-content) {
  padding: 0;
  font-size: var(--p-size-7);
  color: var(--dark-blue);
}

.overlay .imageWrapper {
  position: relative;
  height: 2.25rem;
  margin-bottom: 0.5rem;
}

.overlay .imageWrapper.tall {
  height: 6rem;
}

.overlay .linkWrapper {
  margin-top: 0.5rem;
  font-weight: 800;
  color: var(--primary-blue);
  text-align: right;
}
