.tag {
  display: inline-flex;
}

.tag :global(.ant-tag) {
  padding: 0.25rem 0.5rem;
  border: none;
  background-color: var(--light-blue-2);
  border-radius: var(--border-radius-4);
  color: var(--dark-blue);
  font-size: var(--p-size-7);
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tag span {
  margin: 0;
}
