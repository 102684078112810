.default {
  color: var(--primary-text);
}

.default :global(.ant-checkbox-inner) {
  border-color: var(--primary-blue);
}

.default :global(.ant-checkbox-checked)::after  {
  transition: border-color 0.36s ease-in-out;
}

@media screen and (min-width: 1200px) {
  .default :global(.ant-checkbox-inner) {
    width: 22px;
    height: 22px;
    border-width: 2px;
    border-radius: var(--border-radius-3);
  }

  .default :global(.ant-checkbox-checked)::after {
    border-radius: var(--border-radius-3);
  }
}

.selected :global(.ant-checkbox-inner) {
  border-color: var(--light-green);
  background-color: #fff;
}

.selected:hover :global(.ant-checkbox-inner),
.selected :global(.ant-checkbox:hover .ant-checkbox-inner),
.selected :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--light-green);
}

.selected :global(.ant-checkbox-checked)::after,
.selected :global(.ant-checkbox-inner)::after {
  border-color: var(--light-green);
}

.disabled :global(.ant-checkbox-inner) {
  border-color: var(--light-blue-3);
  background-color: #fff;
}

.disabled :global(.ant-checkbox),
.disabled :global(.ant-checkbox-input),
.disabled:global(.ant-checkbox-wrapper) {
  cursor: not-allowed;
  pointer-events: none;
}

.disabled:hover :global(.ant-checkbox-inner),
.disabled :global(.ant-checkbox:hover .ant-checkbox-inner) {
  border-color: var(--light-blue-3);
}
