@use "styles" as *;

.modal {
  max-width: 480px;
  color: var(--dark-blue);

  h4 {
    margin-bottom: 0.5rem;
    color: inherit;
    font-weight: 700;
    font-size: var(--heading-size-3);
  }

  p {
    font-size: var(--p-size-2);
  }

  .icon {
    margin-bottom: 0.25rem;
    font-size: 3.125rem;
  }

  :global(.ant-modal-content) {
    background-color: var(--light-blue-2);
  }

  :global(.ant-btn) {
    min-width: 80px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;

  :global(.ant-btn) {
    text-transform: uppercase;
    font-weight: 700;
  }
}
