.selector {
  min-width: 80px;
}

.selector :global(.ant-select-arrow) {
  color: var(--primary-blue);
  font-weight: 700;
}

.selector :global(.ant-select-selection-item) {
  font-weight: 700;
  color: var(--dark-gray);
  font-size: var(--p-size-6);
}
