@use "styles" as *;

.button {
  display: inline-block;

  &.isBlock {
    display: block;
  }
}

.button :global(.ant-btn) {
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0.6875rem 1.5rem;
  height: auto;
  border: none;
  border-radius: var(--border-radius-3);
  text-shadow: none;
  letter-spacing: 0.03em;
  font-weight: 700;
  font-size: var(--p-size-6);
}

.button :global(.ant-btn:not(.ant-btn-link):not(.ant-btn-text))::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    143.87deg,
    #004bff -1.39%,
    #ff6047 79.49%,
    #f98d51 91.97%
  );
  content: "";
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);

  clip-path: circle(37.5% at 50% 50%);
}

.button :global(.ant-btn) span,
.button :global(.ant-btn) svg {
  position: relative;
  z-index: 1;
}

.button
  :global(.ant-btn:hover):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  background: var(--primary-blue-hover);
  box-shadow: 0px 1px 3px var(--hover-color);
  color: #fff;
}

.button
  :global(.ant-btn:focus):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  background: var(--primary-blue);
  box-shadow: 0px 1px 3px var(--hover-color);
  color: #fff;
}

.button
  :global(.ant-btn:active):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  color: #fff;
}

.button
  :global(.ant-btn:active):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled)::after {
  opacity: 1;

  clip-path: circle(100% at 50% 50%);
}

.button
  :global(.ant-btn:disabled):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)) {
  background: var(--primary-disabled);
  color: var(--primary-text-disabled);
}

.button :global(.ant-btn-link):hover:not([disabled]),
.button :global(.ant-btn-text):hover:not([disabled]) {
  color: var(--primary-blue-hover);
  text-shadow: 0px 1px 3px rgba(0, 98, 255, 0.3);
}

.button :global(.ant-btn-link):disabled,
.button :global(.ant-btn-text):disabled {
  background: transparent;
  color: var(--primary-text-disabled);
}

.Success {
  :global(.ant-btn):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
    background: var(--light-green);
    color: #fff;
  }
}

.Success
  :global(.ant-btn:hover):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  background: #4bce7f;
  color: #fff;
}

.Success
  :global(.ant-btn:focus):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  background: #4bce7f;
  color: #fff;
}

.Danger
  :global(.ant-btn):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  background: var(--light-red);
  color: #fff;
}

.Danger
  :global(.ant-btn:hover):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  background: #d34a4a;
  color: #fff;
}

.Danger
  :global(.ant-btn:focus):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
  background: #d34a4a;
  color: #fff;
}

.Secondary {
  :global(.ant-btn):not(:global(.ant-btn-link)):not(:global(.ant-btn-text)):not(:global(.ant-btn):disabled) {
    background: var(--light-gray-3);
    color: var(--primary-text);

    &:hover,
    &:focus {
      background: var(--light-gray-3);
      color: var(--primary-text);
    }
  }
}

.upperCase :global(.ant-btn) {
  text-transform: uppercase;
}

.button a:global(.ant-btn) {
  padding: inherit;
  border-radius: var(--border-radius-3);
}

.backButton {
  display: inline-grid;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--p-size-6);

  gap: 0.25rem;
  justify-self: start;
  grid-template-columns: auto 1fr;
  @include unstyle-button(var(--primary-text));

  svg {
    font-size: 0.875rem;
    transform: rotate(90deg);

    path {
      stroke: currentColor;
    }
  }

  &:hover,
  &:focus {
    color: var(--primary-text-disabled);
  }

  &.isBlock {
    display: grid;
    width: 100%;
  }
}
