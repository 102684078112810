header.header {
  position: relative;
  z-index: 100;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.headerMenu {
  flex-wrap: nowrap;
  padding: 0 24px;
  height: 64px;
}

.noWrap {
  flex-wrap: nowrap;
}

.searchBarWrapper {
  line-height: 1;
}

.header .logo {
  padding-left: 20px;
  max-width: 160px;
  max-height: 38px;
  text-align: left;
}

.appLogo {
  display: none;
}

.header :global(.antd-pro-notice-icon-description) {
  font-size: 12px;
  line-height: 0;
}

.header
  :global(.ant-layout.ant-layout-has-sider .ant-layout-sider-collapsed)
  + :global(.ant-layout)
  .appLogo {
  display: block;
}

@media screen and (max-width: 768px) {
  .header .logo {
    max-width: 100px;
  }
  .header a {
    font-size: 12px;
  }
  .appLogo {
    margin-right: 12px;
    width: 80px;
  }
}
