@use "styles" as *;

.stepsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);

  gap: 1rem;
}

.stepsLogo {
  display: inline-flex;

  svg {
    width: 80px;
    height: 19.58px;
  }
}

.stepsContainer {
  display: flex;
  align-items: center;
  color: var(--dark-gray);
  font-size: var(--p-size-5);
}

.currentStep {
  margin-right: 0.25rem;
  margin-bottom: 0;
  background: linear-gradient(
    143.87deg,
    #0062ff -1.39%,
    #ff6047 79.49%,
    #f98d51 91.97%
  );
  -webkit-background-clip: text;
  font-weight: 800;
  font-size: inherit;

  -webkit-text-fill-color: transparent;
}
