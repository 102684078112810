@use "styles" as *;

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 31px;
  height: 31px;
  outline: none;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
  transform: translateY(4px);

  &:focus-within {
    border-color: var(--primary-blue);
    background-color: var(--light-blue-3);
  }

  &Focus {
    border-color: var(--primary-blue);
    background-color: var(--light-blue-3);
  }

  svg path {
    fill: var(--primary-blue);
  }

  :global(.ant-badge) {
    display: inherit;
    font-size: 1.25rem;
  }
}

.wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 100;
  width: 260px;
  border-radius: var(--border-radius-3);
  background-color: #fff;
  box-shadow: $shadow;
  line-height: 1.14;
  transform: translateX(-50%);

  &Lang {
    right: min(-5vw, -210px);
    left: auto;
  }

  > h2 {
    margin-bottom: 0;
    padding: 1rem;
    color: var(--primary-text);
    font-weight: 600;
    font-size: var(--p-size-4);
  }
}

.container {
  position: relative;
  overflow: auto;
  min-height: 85px;
  max-height: 300px;
}

.alert {
  &:global(.ant-notification-notice) {
    padding: 0.75rem;
    max-width: 300px;
    border: 1px solid #ffccc7;
    background: #fff1f0;
  }

  :global(.ant-notification-notice-icon) {
    margin-left: 0;
    font-size: 1rem;
  }
}
