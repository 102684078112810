.content {
  min-height: 100vh;
}

@media screen and (min-width: 1200px) {
  .fullHeigth {
    min-height: 985px;
    height: 100vh;
  }
}

.displayBadge {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 11;
  display: inline-block;
}

.displayMails {
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.44);
}
