@use "styles" as *;

.radioGroup {
  position: relative;
  height: 100%;
}

.radioGroup :global(.ant-radio-group) {
  display: flex;
  flex-wrap: wrap;
}

.radioButton {
  position: relative;
}

.radioButton :global(.ant-radio-button-wrapper) {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  height: auto;
  border: 2px solid var(--light-blue-3);
  color: var(--dark-blue);
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.radioButton
  :global(.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)):hover {
  box-shadow: 0px 1px 3px var(--hover-color);
}

.radioButton :global(.ant-radio-button-wrapper:first-child:last-child) {
  border-radius: var(--border-radius-3);
}

.radioButton :global(.ant-radio-button-wrapper-disabled) {
  border-color: var(--primary-disabled);
  background-color: #fff;
  color: var(--primary-text-disabled);
}

.radioButton
  :global(.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper):first-child:not(:hover):not(:active) {
  border-right-color: var(--primary-blue);
}

.radioButton
  :global(.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child):active {
  border-right-color: #004cd9;
}

.radioButton
  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)):hover,
.radioButton
  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)):active {
  color: var(--dark-blue);
}

.radioCustom {
  display: inline-grid;
  align-items: center;
  margin-right: 0;
  color: inherit;
  white-space: normal;
  font-weight: 700;
  font-size: var(--p-size-7);

  grid-template-columns: auto 1fr;
  :global(.ant-radio-inner) {
    border-color: var(--primary-blue);

    @include media-breakpoint-up(lg) {
      width: 22px;
      height: 22px;
      border-width: 2px;

      &::after {
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
      }
    }
  }
}
