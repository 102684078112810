.appLogo {
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.appLogo img {
  max-width: 150px;
}

.sider {
  position: relative;
  z-index: 10;
  min-height: 100vh;
  max-width: 256px;
  background-color: var(--light-blue-2);
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}

.siderWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.menu {
  background: inherit;
}

.menu li:global(.ant-menu-item) button,
.menu :global(.ant-menu-submenu) button {
  font-weight: normal;
}

.quotingButtonWrapper {
  margin: 24px 0 16px;
  text-align: center;
}

.menu li:global(.ant-menu-item.ant-menu-item-disabled) a,
.menu
  :global(.ant-menu-submenu-disabled)
  a
  .menu
  :global(.ant-menu-submenu-title)
  span:disabled {
  color: var(--primary-text-disabled) !important;
}

.quotingButton {
  width: 80%;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-weight: 700;
  font-size: 15px;
}

.sider :global(.ant-menu-light) {
  border-right-color: transparent;
}

.submenu :global(.ant-menu-submenu-title) {
  margin-bottom: 10px;
}

.submenu :global(.ant-menu.ant-menu-sub.ant-menu-inline) {
  background: rgba(238, 238, 238, 0.5);
}

.sider :global(.ant-menu-submenu-open .ant-menu-sub) {
  background-color: #f2f8fd;
}

.sider :global(.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline) {
  margin-top: -5px;
  margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
  .sider :global(.ant-layout-sider-children) {
    margin-top: 12px;
    margin-bottom: 32px;
  }
  .sider :global(.ant-layout-sider-zero-width-trigger) {
    height: 40px;
    border: 1px solid #dfdfdf;
    border-left: none;
  }
  .sider {
    position: fixed;
  }
}
