.stepItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 0;
  width: 32px;
  height: 32px;
  border: 2px solid var(--light-blue-3);
  border-radius: var(--border-radius-3);
  background: linear-gradient(
    120.04deg,
    #0062ff -5.36%,
    #ff6047 87.93%,
    #f98d51 103.19%
  );
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}

.stepIcon {
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
}
