:root {
  --primary-blue: #0062ff;
  --primary-blue-hover: #0053d9;
  --primary-orange: #ff6200;
  --dark-blue: #101858;
  --primary-text: #121857;
  --primary-text-disabled: rgba(18, 24, 87, 0.4);
  --primary-text-color: #666666;
  --primary-disabled: #eeeeee;
  --primary-placeholder: #66a2ff;
  --primary-blue-500: rgba(0, 98, 255, 0.5);
  --primary-red: #ff4d4f;
  --dark-blue-disabled: rgba(0, 98, 255, 0.2);
  --secondary-orange: #f98d51;
  --light-gray: #f7f8fb;
  --light-gray-2: #e7e7e7;
  --light-gray-3: #dedede;
  --light-gray-4: #e5e5e5;
  --light-gray-5: #00000073;
  --light-blue: #f5f7fa;
  --light-blue-2: #f1f6ff;
  --light-blue-3: #cce0ff;
  --light-blue-4: #89b0ef;
  --light-blue-5: #f1f6fe;
  --light-green: #73dd9d;
  --light-red: #dd7373;
  --step-line-activated: #4bce7f;
  --step-activated-text: #ffffff;
  --red-button: #ff0000;
  --default-black: #000000;
  --card-content-color: #fff;
  --dark-gray: #555555;
  --hover-color: rgba(0, 98, 255, 0.3);
  --disabledContent: #c4c4c4;
  --heading-size-1: 2.375rem; /* 38px */
  --heading-size-2: 2.125rem; /* 34px */
  --heading-size-3: 1.75rem; /* 28px */
  --heading-size-4: 1.5rem; /* 24px */
  --heading-size-5: 1.25rem; /* 20px */
  --p-size-1: 1.5rem; /* 24px */
  --p-size-2: 1.125rem; /* 18px */
  --p-size-3: 1rem; /* 16px */
  --p-size-4: 0.9375rem; /* 15px */
  --p-size-5: 0.875rem; /* 14px */
  --p-size-6: 0.75rem; /* 12px */
  --p-size-7: 0.625rem; /* 10px */
  --p-size-8: 0.5rem; /* 8px */
  --border-radius-1: 25px;
  --border-radius-2: 12px;
  --border-radius-3: 8px;
  --border-radius-4: 4px;
  --line-height-1: 1.375rem; /* 22px */
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: "Open Sans";
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("/static/fonts/open-sans-v18-latin-300.woff2") format("woff2"),
    url("/static/fonts/open-sans-v18-latin-300.woff") format("woff");

  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Open Sans";
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("/static/fonts/open-sans-v18-latin-regular.woff2") format("woff2"),
    url("/static/fonts/open-sans-v18-latin-regular.woff") format("woff");

  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: "Open Sans";
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("/static/fonts/open-sans-v18-latin-600.woff2") format("woff2"),
    url("/static/fonts/open-sans-v18-latin-600.woff") format("woff");

  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Open Sans";
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("/static/fonts/open-sans-v18-latin-700.woff2") format("woff2"),
    url("/static/fonts/open-sans-v18-latin-700.woff") format("woff");

  font-display: swap;
}

@font-face {
  font-weight: 800;
  font-style: normal;
  font-family: "Open Sans";
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("/static/fonts/open-sans-v18-latin-800.woff2") format("woff2"),
    url("/static/fonts/open-sans-v18-latin-800.woff") format("woff");

  font-display: swap;
}

body {
  font-size: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin: 24px;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  margin-right: 8px;
  padding-right: 0;
}

.ant-input-number-handler-wrap {
  display: none;
  pointer-events: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
