.divStyle :global(.ant-alert.ant-alert-no-icon){
  padding: 8px 24px;
  background: #faad14;
}

.divStyle i {
  color :#faad14;
}
  
.alertDescription {
  color: #FFFFFF;
}