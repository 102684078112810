@use "styles" as *;

%contentCommon {
  margin-bottom: 0;
  transition: color 0.3s ease-out;
}

.radioGroup {
  display: block;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.item {
  position: relative;
  overflow: hidden;
  height: 100%;
  border: 2px solid var(--dark-blue-disabled);
  border-radius: var(--border-radius-3);
  background-color: var(--light-blue-2);
  box-shadow: $shadow;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    min-height: 260px;
  }

  &:hover,
  &:focus,
  &.selected {
    padding: 2px;
    border: none;
    cursor: pointer;

    .overlay {
      opacity: 1;

      clip-path: circle(100% at 50% 50%);
    }

    .content {
      color: #fff;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120.04deg,
    #0062ff -5.36%,
    #ff6047 87.93%,
    #f98d51 103.19%
  );
  opacity: 0;
  transition: all 0.36s ease-in, opacity 0.1s linear;
  pointer-events: none;

  clip-path: circle(37.5% at 50% 50%);
}

.iconWrapper {
  position: relative;
  z-index: 1;
  padding: 1.5rem 0.875rem 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: #fff;
  text-align: center;
  font-size: 2.25rem;
  cursor: pointer;

  :global(.anticon) {
    transition: transform 0.32s ease-out;
  }

  &::after {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: inherit;
    content: "";

    -webkit-clip-path: ellipse(52% 5% at 47% 56%);
    clip-path: ellipse(70% 41% at 50% 50%);
  }
}

.content {
  position: relative;
  z-index: 1;
  display: grid;
  padding: 2rem 0.875rem 1rem;
  color: var(--primary-text);

  grid-template-rows: minmax(3rem, 1fr) auto;
  row-gap: 0.5rem;
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.serviceName {
  color: inherit;
  font-weight: 700;
  font-size: var(--p-size-5);

  @extend %contentCommon;
}

.description {
  font-size: var(--p-size-3);

  @extend %contentCommon;
}

.price {
  position: relative;
  font-weight: 700;
  font-size: var(--heading-size-3);

  @extend %contentCommon;

  small {
    position: absolute;
    margin-left: 0.25rem;
    font-weight: 400;
    font-size: var(--p-size-6);
  }
}

.serviceDescription {
  font-size: var(--p-size-7);
  line-height: 1.2;

  @extend %contentCommon;
}
