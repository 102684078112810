@use "styles" as *;

$antdBreakpoint: 480px;

.stepsContainer {
  background-color: var(--card-content-color);

  @include media-breakpoint-up(xs) {
    overflow: auto;
  }

  :global(.ant-steps-item-container) {
    @media (max-width: $antdBreakpoint) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }

  :global(.ant-steps-horizontal.ant-steps-label-horizontal) {
    @media (max-width: $antdBreakpoint) {
      flex-direction: row;
    }

    :global(.ant-steps-item-content) {
      @media (max-width: $antdBreakpoint) {
        min-height: auto;
      }
    }
  }

  :global(.ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item:not(:last-child)
      > .ant-steps-item-container
      > .ant-steps-item-tail) {
    @media (max-width: $antdBreakpoint) {
      top: 18px;
      margin: 0 0 0 calc(50% + 8px);
      padding: 0;
      width: 100%;
      height: auto;
    }
  }

  :global(.ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail:after) {
    width: calc(100% - 44px);
    height: 3px;
    border-radius: 3px;
  }

  :global(.ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail:after) {
    background-color: var(--light-green);
  }

  :global(.ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title) {
    color: var(--dark-blue);
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: var(--p-size-5);
    }
    @include media-breakpoint-down(xs) {
      font-size: var(--p-size-6);
    }

    @media (max-width: $antdBreakpoint) {
      font-size: var(--p-size-7);
      line-height: 1;
    }
  }

  :global(.ant-steps-item-icon) {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    border-width: 2px;
    border-color: var(--light-blue-3);
    border-radius: var(--border-radius-3);
    background: linear-gradient(
      120.04deg,
      #0062ff -5.36%,
      #ff6047 87.93%,
      #f98d51 103.19%
    );

    :global(.ant-steps-icon) {
      top: 0;
      font-weight: 600;

      svg {
        font-size: 0.875rem;
      }
    }
  }

  :global(.ant-steps-item-wait .ant-steps-item-icon) {
    background: var(--primary-disabled);

    > :global(.ant-steps-icon) {
      color: var(--primary-text-disabled);
    }
  }

  :global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon) {
    color: #fff;
  }

  :global(.ant-steps-horizontal.ant-steps-label-horizontal
      .ant-steps-item-icon) {
    @media (max-width: $antdBreakpoint) {
      margin-right: 0;
      margin-bottom: 0.375rem;
    }
  }
}

.stepsContainer :global(.ant-steps-item-title)::after {
  height: 3px;
}

.stepsContainer
  :global(.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title)::after,
.stepsContainer
  :global(.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title)::after {
  background-color: var(--light-blue-3);
}

.stepsContainer
  :global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title)::after {
  background-color: var(--light-green);
}

.stepsContainer
  :global(.ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon) {
  border-color: var(--light-blue-3);
}

.stepsContainer
  :global(.ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon
    .ant-steps-icon) {
  color: #fff;
}
