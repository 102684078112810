.select {
  display: block;
  color: var(--dark-blue);
}

.select:global(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  padding: 0.5rem 1rem;
  height: auto;
  border-width: 2px;
  border-color: var(--primary-blue-500);
  border-radius: var(--border-radius-3);
  text-align: left;
}

.select:global(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector::after),
.select:global(.ant-select-single
    .ant-select-selector
    .ant-select-selection-item) {
  line-height: 21px;
}

.select:global(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input) {
  height: 100%;
}

.select:global(.ant-select-single
    .ant-select-selector
    .ant-select-selection-search) {
  left: 1rem;
  line-height: 21px;
}

.select:hover,
.select:focus {
  filter: drop-shadow(0px 1px 3px rgba(0, 98, 255, 0.3));
}

.select:global(.ant-select:not(.ant-select-disabled):hover
    .ant-select-selector),
.select:global(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border-right-width: 2px !important;
}

.select :global(.ant-select-selector .ant-select-selection-placeholder) {
  color: var(--primary-text-disabled);
  text-align: left;
  font-weight: 400;
  line-height: 21px;
}

.select :global(.ant-select-arrow) {
  color: var(--primary-blue-500);
}

.select :global(.ant-select-selection-search-input) {
  color: var(--dark-blue);
}

.dropdownClassName {
  border-radius: var(--border-radius-3);
}

.selectAlt {
  position: relative;
  padding: 0.625rem 0.875rem;
  border: 2px solid var(--light-blue-3);
  border-radius: var(--border-radius-3);
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.24s ease-in;

  > label {
    display: block;
    margin-bottom: 0.25rem;
    color: var(--primary-placeholder);
    font-weight: 600;
    cursor: inherit;
  }

  :global(.ant-select) {
    width: 100%;
  }

  :global(.ant-select-arrow) {
    right: 0;
    color: var(--light-blue-3);
  }

  :global(.ant-select-single
      .ant-select-selector
      .ant-select-selection-search) {
    left: 0;
  }

  :global(.ant-select-single
      .ant-select-selector
      .ant-select-selection-placeholder),
  :global(.ant-select-single .ant-select-selector .ant-select-selection-item) {
    font-weight: 600;
    line-height: 21px;
  }

  :global(.ant-select-single
      .ant-select-selector
      .ant-select-selection-placeholder) {
    color: var(--primary-text-disabled);
  }

  :global(.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector) {
    padding: 0;
    height: auto;
    border: 0;
    border-radius: 0;
    background-color: transparent;

    &::after {
      line-height: normal;
    }
  }

  :global(.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input) {
    height: 100%;
    font-weight: 600;
  }

  :global(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector) {
    box-shadow: none;
  }

  &:hover {
    box-shadow: 0px 1px 3px var(--hover-color);
  }

  &.selectAltFocus {
    border-color: var(--primary-blue);
    box-shadow: 0px 1px 3px var(--hover-color);
  }
}

:global(.ant-form-item-has-error) {
  .selectAlt {
    border-color: var(--primary-red);

    > label {
      color: var(--primary-text);
    }

    &.selectAltFocus {
      box-shadow: 0px 1px 3px var(--light-red);
    }
  }
}

.dropdownRef {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  max-height: 256px;
  width: 100%;
}

.dropdownRefContainer {
  position: absolute;
  top: calc(100% + 0.5rem) !important;
  right: 0;
  left: 0 !important;
  border-radius: var(--border-radius-3);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
