.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  background-color: rgba(#fff, 0.75);
  cursor: not-allowed;
  pointer-events: none;
}

.dot {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: var(--primary-blue);
  transition: all 0.3s ease-in;
  animation: dotTransition 0.825s infinite both;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
}

.rowSkeleton {
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-width: 7rem;
  height: 1.75rem;
  border-radius: var(--border-radius-3);
  background-color: var(--light-gray-2);
  vertical-align: middle;

  &::after {
    position: absolute;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      var(--light-gray),
      transparent
    );
    content: "";
    transform: translateX(-100%);
    animation: slideTranslate 1.4s ease infinite;;
  }
}

@keyframes dotTransition {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  30%,
  50% {
    opacity: 1;
  }
}

@keyframes slideTranslate {
  100% {
    transform: translateX(100%);
  }
}
