.footer {
  text-align: center;
}

.newVersionNotification :global(.ant-notification-notice-message) {
  font-weight: 500;
}

.newVersionNotification button {
  float: left;
  padding: 0;
}

.changelogVersion {
  color: var(--primary-blue);
}
.appStyle {
  background-color: #FFFFFF;
}