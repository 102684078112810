@use "styles" as *;

.gradientContainer {
  z-index: 2;
  display: grid;
  padding: 0.5rem;
  width: 100%;
  border-radius: var(--border-radius-3);
  background: linear-gradient(
    143.87deg,
    #0062ff -1.39%,
    #ff6047 79.49%,
    #f98d51 91.97%
  );
  box-shadow: $shadow;

  gap: 0.75rem;
}

.gradientContainerSmall {
  padding: 0.1875rem;
}
