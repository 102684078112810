.card :global(.ant-card),
.background :global(.ant-card) {
  border-radius: var(--border-radius-3);
}

.card.bordered :global(.ant-card.ant-card-bordered) {
  border: 2px solid var(--dark-blue-disabled);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
}

.card :global(.ant-card) {
  border: none;
}

.card,
.background {
  height: 100%;
}

.card.shadowed :global(.ant-card) {
  box-shadow: 0px 0px 10px 0px #00000024;
}

.background > div {
  background-color: var(--light-blue-2);
}

.cardBorder {
  background: linear-gradient(
    120.04deg,
    #0062ff -5.36%,
    #ff6047 87.93%,
    #f98d51 103.19%
  );
  padding: 0.5rem;
  border-radius: var(--border-radius-3);
  width: 100%;
  position: relative;
  z-index: 2;
}

.title {
  font-size: 1rem;
  color: var(--dark-blue);
  line-height: 19px;
  font-weight: 600;
  position: relative;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
