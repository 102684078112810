.divStyle {
  padding: 0.5rem 1.25rem 1.25rem;
}

.textContainer {
  padding: 1.5rem 0.75rem;
  border-radius: var(--border-radius-2);
  background-color: #fff;
  color: var(--primary-text-color);
  font-size: 0.8125rem;
  font-weight: 600;
}

.textContainer button {
  display: inline-block;
  padding: 0;
  font-weight: 700;
}