@use "styles" as *;

.default {
  width: 100%;
  border: 3px solid var(--primary-blue);
  border-radius: var(--border-radius-3);
  background-color: #fff;
  box-shadow: $shadow;
  transition: border 0.32s cubic-bezier(0.02, 0.29, 0.36, 0.95);
}

.selected {
  border-color: var(--light-green);
}

.backup {
  border-color: var(--secondary-orange);
}

.disabled {
  border-color: var(--light-blue-3);
}
