.input :global(.ant-input) {
  padding: 0.5rem 1rem;
  border-width: 2px;
  border-radius: var(--border-radius-3);
  color: var(--dark-blue);
}

.input :global(.ant-input:not(:focus):not(.ant-input-focused):not(:disabled)) {
  border-color: var(--primary-blue-500);
}

.input :global(.ant-input)::placeholder {
  color: var(--primary-text-disabled);
  font-weight: 400;
}

.input :global(.ant-input):hover,
.input :global(.ant-input):focus {
  border-right-width: 2px !important;
}

.input :global(.ant-input-group) {
  display: grid;

  grid-template-columns: 0.625rem 3.25rem 1fr;
}

.input :global(.ant-input-group-addon) {
  z-index: 10;
  align-self: center;
  padding: 0;
  width: auto;
  border-right: 1px solid #d9d9d9;
  border-radius: var(--border-radius-4);
  background-color: #fff;
  color: var(--primary-text-disabled);
  font-weight: 600;
  font-size: var(--p-size-6);

  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.input :global(.ant-input-group .ant-input) {
  padding-left: calc(0.625rem * 1.5 + 3.25rem);

  grid-column: 1 / -1;
  grid-row: 1 / 2;
}

.input :global(.ant-input-group > .ant-input:last-child),
.input :global(.ant-input-group-addon:last-child) {
  border-top-left-radius: var(--border-radius-3);
  border-bottom-left-radius: var(--border-radius-3);
}

:global(.ant-form-item-has-error) {
  .input
    :global(.ant-input:not(:focus):not(.ant-input-focused):not(:disabled)) {
    border-color: var(--primary-red);
  }
}

%inputAltFocus {
  border-right-width: 0 !important;
  box-shadow: none;
}

.inputAlt {
  padding: 0.625rem 0.875rem;
  border: 2px solid var(--light-blue-3);
  border-radius: var(--border-radius-3);
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.24s ease-in;

  :global(.ant-input) {
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    color: var(--primary-text);
    font-weight: 600;

    &:focus {
      @extend %inputAltFocus;
    }
  }

  :global(.ant-input-focused) {
    @extend %inputAltFocus;
  }

  :global(.ant-input::-webkit-input-placeholder) {
    color: var(--primary-text-disabled);
  }

  :global(.ant-input:-ms-input-placeholder) {
    color: var(--primary-text-disabled);
  }

  :global(.ant-input::-ms-input-placeholder) {
    color: var(--primary-text-disabled);
  }

  :global(.ant-input::placeholder) {
    color: var(--primary-text-disabled);
  }

  :global(.ant-input-affix-wrapper) {
    padding: 0;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }

    &:global(.input.ant-input) {
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  > label {
    display: block;
    margin-bottom: 0.25rem;
    color: var(--primary-placeholder);
    font-weight: 600;
    transition: color 0.3s ease;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:hover {
    box-shadow: 0px 1px 3px var(--hover-color);
  }

  &.inputAltFocus {
    border-color: var(--primary-blue);
    box-shadow: 0px 1px 3px var(--hover-color);
  }
}

.inputAlt {
  :global(.ant-input-number) {
    display: block;
    width: auto;
    border: 0;
    border-radius: 0;
    color: var(--primary-text);

    &:focus {
      @extend %inputAltFocus;
    }
  }

  :global(.ant-input-number-focused) {
    @extend %inputAltFocus;
  }

  :global(.ant-input-number-input) {
    padding: 0;
    height: auto;
    border-radius: 0;
    font-weight: 600;
  }
}

.inputAddon {
  display: inline-block;

  :global(.ant-input-wrapper) {
    border: 2px solid var(--light-blue-3);
    border-radius: var(--border-radius-3);
  }

  :global(.ant-input) {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: inherit;
    color: var(--dark-blue);
    font-weight: 600;
  }

  :global(.ant-input-group-addon) {
    border: none;
    border-left: 2px solid var(--light-blue-3);
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: var(--light-blue-2);

    :global(.ant-select),
    :global(.ant-select-arrow) {
      color: var(--primary-placeholder);
    }
  }

  :global(.ant-select-selector) {
    font-weight: 600;
  }

  :global(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector) {
    border-color: transparent;
    box-shadow: none;
  }
}

:global(.ant-form-item-has-error) {
  .inputAlt {
    border-color: var(--primary-red);

    > label {
      color: var(--primary-text);
    }

    &.inputAltFocus {
      box-shadow: 0px 1px 3px var(--light-red);
    }
  }
}
