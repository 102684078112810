.divider {
    margin-bottom: 8px;
    margin-top: 8px;
}
.sectionContainer {
    padding-top: 8px;
    padding-left: 8px;
}
.namePanel {
    text-align: center;
    margin: -6px 0;
  }
.title {
    color: #0062ff;
    text-align: center;
}