.notification {
  padding: 1rem;
  background-color: var(--light-blue-2);

  &:not(:last-child) {
    border-bottom: 1px solid var(--light-blue-3);
  }

  > h4 {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--primary-text);
    font-size: var(--p-size-5);

    gap: 0.25rem;
  }

  &.isRead {
    background-color: #fff;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 0.5rem;

  span {
    font-weight: 300;
    font-size: var(--p-size-6);
  }

  a {
    font-size: var(--p-size-6);
  }
}
